<template>
    <div>
        <div class="sm:flex sm:items-center">
            <div class="sm:flex-auto">
                <h1 class="text-2xl font-semibold leading-6 text-gray-900">Login count</h1>
                <p class="mt-2 text-sm text-gray-700">En çok oturum açmış kullanıcıları gösterir.
                </p>
            </div>
            <!-- <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                <button type="button"
                    class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Export</button>
            </div> -->
        </div>
        <div class="mt-8 flow-root">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table class="min-w-full divide-y divide-gray-300"
                        v-if="reportUserByLoginCountResult && reportUserByLoginCountResult.items">
                        <thead>
                            <tr>
                                <th scope="col"
                                    class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                    e-Mail</th>
                                <th scope="col"
                                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Login count</th>
                                <th scope="col"
                                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Last login date</th>

                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200 bg-white">
                            <tr v-for="item in reportUserByLoginCountResult.items" :key="item.eMail">
                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{{ item.eMail
                                }}</td>
                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">{{
                                    item.loginCount }}</td>
                                <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{{
                                    formatDateLong(item.lastLoginDate) }} </td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ActionTypes } from "@/store/action-types";
import { ReportUserByLoginCountRequest, ReportUserByLoginCountResult } from "@/types/SharedTypes";
import { StaticPhrases } from "@/types/StaticPhrases";
import StringHelper from "@/types/StringHelper";
import axios from "axios";
import { nanoid } from "nanoid";
import { defineComponent } from "vue";


const transactions = [
    {
        id: 'AAPS0L',
        company: 'Chase & Co.',
        share: 'CAC',
        commission: '+$4.37',
        price: '$3,509.00',
        quantity: '12.00',
        netAmount: '$4,397.00',
    },
    // More transactions...
]

export default defineComponent({
    name: "ReportUserByLoginCount",
    components: {
    },
    data() {
        return {
            transactions: transactions,
            reportUserByLoginCountResult: null as null | ReportUserByLoginCountResult
        };
    },
    mounted() {
        this.reportUserByLoginCount();
    },
    methods: {
        reportUserByLoginCount() {
            const yourConfig = {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.getIdToken,
                },
            };
            const req = {
                loginSessionId: this.$store.getters.getLoginSessionId,
                requestId: nanoid(),

            } as ReportUserByLoginCountRequest;
            axios
                .post(this.$store.getters.getApiUrl + "admin/reportUserByLoginCount", req, yourConfig)
                .then((e) => {
                    if (e.data.error !== null) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, e.data.error);
                    } else {
                        const result = e.data.result as ReportUserByLoginCountResult;
                        this.reportUserByLoginCountResult = result;
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error", error);
                        if (error.response.status === 401) {
                            this.$store.dispatch(ActionTypes.TOASTERROR, "Yetkisiz erişim.");
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTERROR, error.response);
                        }
                    } else if (error.request) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, StaticPhrases.serverNotFoundError);
                    } else {
                        this.$store.dispatch(ActionTypes.TOASTERROR, error.message);
                    }
                })
                .finally(() => {
                    //
                });
        },
        formatDateLong(inputDate: string | null): string {
            if (inputDate) return StringHelper.formatDateLong(inputDate);
            return "";
        },
    }

});

</script>