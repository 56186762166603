import { MutationTree } from "vuex";
import { MutationTypes } from "./mutation-types";
import { State } from "./state";

export type Mutations<S = State> = {
    [MutationTypes.SET_IDTOKEN](state: S, payload: string | undefined): void;
    [MutationTypes.SET_LOGINSESSIONID](state: S, payload: string | undefined): void;
    [MutationTypes.SET_APIURL](state: S, payload: string): void;
};

export const mutations: MutationTree<State> & Mutations = {
    [MutationTypes.SET_IDTOKEN](state, payload: string | undefined) {
        state.idToken = payload;
    },
    [MutationTypes.SET_LOGINSESSIONID](state, payload: string | undefined) {
        state.loginSessionId = payload;
    },
    [MutationTypes.SET_APIURL](state, payload: string) {
        state.apiUrl = payload;
    },
};
