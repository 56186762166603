<template>
    <div>
        <div class="sm:flex sm:items-center">
            <div class="sm:flex-auto">
                <h1 class="text-2xl font-semibold leading-6 text-gray-900">Logins</h1>
                <p class="mt-2 text-sm text-gray-700">Kriter girilmez ise son 1 aydaki oturumları gösterir.
                </p>
            </div>
            <!-- <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                <button type="button"
                    class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Export</button>
            </div> -->
        </div>
        <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div class="sm:col-span-3">
                <label for="usermail" class="block text-sm font-medium leading-6 text-gray-900">User e-mail</label>
                <div class="mt-2">
                    <input type="text" name="usermail" id="usermail" autocomplete="off" v-model="email"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
            </div>

            <div class="sm:col-span-3">
                <label for="userId" class="block text-sm font-medium leading-6 text-gray-900">User Id</label>
                <div class="mt-2">
                    <input type="text" name="userId" id="userId" autocomplete="off" v:model="userId"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>
            </div>
            <div class="sm:col-span-3">

                <div class="mt-2">
                    <button type="button" @click="reportUserLogin()"
                        class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">List</button>
                </div>
            </div>
        </div>
        <div class="mt-8 flow-root">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table class="min-w-full divide-y divide-gray-300"
                        v-if="reportUserLoginResult && reportUserLoginResult.items">
                        <thead>
                            <tr>
                                <th scope="col"
                                    class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                    e-Mail</th>
                                <th scope="col"
                                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Name</th>
                                <th scope="col"
                                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Login date</th>
                                <th scope="col"
                                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    IP</th>
                                <th scope="col"
                                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Agent</th>
                                <th scope="col"
                                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Organization</th>

                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200 bg-white">
                            <tr v-for="item in reportUserLoginResult.items" :key="item.eMail">
                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{{ item.eMail
                                }}</td>
                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">{{
                                    item.displayName }}</td>
                                <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{{
                                    formatDateLong(item.createdDate) }} </td>
                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">{{
                                    item.clientIP }}</td>
                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">{{
                                    item.clientUserAgent }}</td>
                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">{{
                                    item.organizationDisplayName }}</td>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ActionTypes } from "@/store/action-types";
import { ReportUserLoginRequest, ReportUserLoginResult } from "@/types/SharedTypes";
import { StaticPhrases } from "@/types/StaticPhrases";
import StringHelper from "@/types/StringHelper";
import axios from "axios";
import { nanoid } from "nanoid";
import { defineComponent } from "vue";




export default defineComponent({
    name: "ReportUserLogin",
    components: {
    },
    data() {
        return {
            userId: null as null | string,
            email: null as null | string,
            reportUserLoginResult: null as null | ReportUserLoginResult
        };
    },
    mounted() {
        this.reportUserLogin();
    },
    methods: {
        reportUserLogin() {
            const yourConfig = {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.getIdToken,
                },
            };
            const req = {
                loginSessionId: this.$store.getters.getLoginSessionId,
                requestId: nanoid(),
                userId: this.userId,
                userEMail: this.email
            } as ReportUserLoginRequest;
            axios
                .post(this.$store.getters.getApiUrl + "admin/reportUserLogin", req, yourConfig)
                .then((e) => {
                    if (e.data.error !== null) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, e.data.error);
                    } else {
                        const result = e.data.result as ReportUserLoginResult;
                        this.reportUserLoginResult = result;
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error", error);
                        if (error.response.status === 401) {
                            this.$store.dispatch(ActionTypes.TOASTERROR, "Yetkisiz erişim.");
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTERROR, error.response);
                        }
                    } else if (error.request) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, StaticPhrases.serverNotFoundError);
                    } else {
                        this.$store.dispatch(ActionTypes.TOASTERROR, error.message);
                    }
                })
                .finally(() => {
                    //
                });
        },
        formatDateLong(inputDate: string | null): string {
            if (inputDate) return StringHelper.formatDateLong(inputDate);
            return "";
        },
    }

});

</script>