<template>
    <div class="mt-8">
        <div class="text-xl px-4 py-2 text-gray-400">PAYTR Payments</div>
        <input type="file" id="file" ref="file" multiple v-on:change="handleFileUpload()" style="position: absolute; top: -500px" accept="application/pdf" />
        <div class="flex flex-col space-y-2 w-full p-8">
            <table class="table-auto" :class="[waiting ? 'bg-gradient-to-r from-gray-100 via-gray-50 to-gray-100 text-transparent' : '']" v-if="getPayTrPaymentsResult && getPayTrPaymentsResult.items">
                <thead>
                    <tr>
                        <th class="text-center">Id</th>
                        <th class="text-center">Users e-Mail</th>
                        <th class="text-center">Users Name</th>
                        <th class="text-right">Amount</th>
                        <th class="text-right">Created</th>
                        <th class="text-center">Payment Type</th>
                        <th class="text-center">Receipt Type</th>
                        <th class="text-center">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in getPayTrPaymentsResult.items" :key="item.id">
                        <td class="text-center">{{ item.id }}</td>
                        <td class="text-center">{{ item.userEMail }}</td>
                        <td class="text-center">{{ item.userDisplayName }}</td>
                        <td class="text-right">{{ formatDecimal(item.amount) + " TL + " + formatDecimal(item.amountKdv) + " TL" }}</td>
                        <td class="text-right">{{ item.createdDateString }}</td>
                        <td class="text-center">{{ item.paymentTypeString }}</td>
                        <td class="text-center">{{ item.receiptTypeString }}</td>
                        <td class="text-center">
                            <button class="btn-one" @click="openDetails(item)">Details</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-else>No Record.</div>
            <div class="p-5"></div>
            <div v-if="selectedItem" class="flex space-x-6 border border-gray-200">
                <table class="table-auto m-4">
                    <tbody>
                        <tr>
                            <td class="text-right font-medium">Id</td>
                            <td class="text-left pl-4">{{ selectedItem.id }}</td>
                        </tr>
                        <tr>
                            <td class="text-right font-medium">Receipt Id</td>
                            <td class="text-left pl-4">{{ selectedItem.receiptId }}</td>
                        </tr>
                        <tr>
                            <td class="text-right font-medium">Payment Type</td>
                            <td class="text-left pl-4">{{ selectedItem.paymentTypeString }}</td>
                        </tr>
                        <tr>
                            <td class="text-right font-medium">Receipt Type</td>
                            <td class="text-left pl-4">{{ selectedItem.receiptTypeString }}</td>
                        </tr>
                        <tr>
                            <td class="text-right font-medium">Company Tax No</td>
                            <td class="text-left pl-4">{{ selectedItem.receiptAddressCompanyTaxNo }}</td>
                        </tr>
                        <tr>
                            <td class="text-right font-medium">Company Tax Office</td>
                            <td class="text-left pl-4">{{ selectedItem.receiptAddressCompanyTaxOffice }}</td>
                        </tr>
                        <tr>
                            <td class="text-right font-medium">Citizenship No</td>
                            <td class="text-left pl-4">{{ selectedItem.receiptAddressCitizenshipNo }}</td>
                        </tr>
                        <tr>
                            <td class="text-right font-medium">City</td>
                            <td class="text-left pl-4">{{ selectedItem.receiptAddressCityName }}</td>
                        </tr>
                        <tr>
                            <td class="text-right font-medium">Country</td>
                            <td class="text-left pl-4">{{ selectedItem.receiptAddressCountry }}</td>
                        </tr>
                        <tr>
                            <td class="text-right font-medium">Address</td>
                            <td class="text-left pl-4">{{ selectedItem.receiptAddressAddress }}</td>
                        </tr>
                        <tr>
                            <td class="text-right font-medium">First Name</td>
                            <td class="text-left pl-4">{{ selectedItem.receiptAddressPersonFirstName }}</td>
                        </tr>
                        <tr>
                            <td class="text-right font-medium">Last Name</td>
                            <td class="text-left pl-4">{{ selectedItem.receiptAddressPersonLastName }}</td>
                        </tr>
                        <tr>
                            <td class="text-right font-medium">Zip Code</td>
                            <td class="text-left pl-4">{{ selectedItem.receiptAddressZipCode }}</td>
                        </tr>
                        <tr>
                            <td class="text-right font-medium">Last 4 Digits</td>
                            <td class="text-left pl-4">{{ selectedItem.lastFourDigits }}</td>
                        </tr>
                        <tr>
                            <td class="text-right font-medium">Package Name</td>
                            <td class="text-left pl-4">{{ selectedItem.purchasedPackageName }}</td>
                        </tr>
                        <tr>
                            <td class="text-right font-medium">User License Count</td>
                            <td class="text-left pl-4">{{ selectedItem.userLicenseCount }}</td>
                        </tr>
                        <tr>
                            <td class="text-right font-medium">Amount</td>
                            <td class="text-left pl-4">{{ formatDecimal(selectedItem.amount) + " TL" }}</td>
                        </tr>
                        <tr>
                            <td class="text-right font-medium">KDV</td>
                            <td class="text-left pl-4">{{ formatDecimal(selectedItem.amountKdv) + " TL" }}</td>
                        </tr>
                        <tr>
                            <td class="text-right font-medium">Total</td>
                            <td class="text-left pl-4">{{ formatDecimal(selectedItem.amount + selectedItem.amountKdv) + " TL" }}</td>
                        </tr>
                        <tr>
                            <td class="text-right font-medium">PayTR Payment Amount</td>
                            <td class="text-left pl-4">{{ selectedItem.payTrPaymentAmount + " TL" }}</td>
                        </tr>
                        <tr>
                            <td class="text-right font-medium">PayTR Total Amount</td>
                            <td class="text-left pl-4">{{ selectedItem.payTrTotalAmount + " TL" }}</td>
                        </tr>
                    </tbody>
                </table>
                <div class="flex space-x-2 border-l border-gray-200">
                    <div class="flex flex-col space-y-2 m-5">
                        <input type="text" placeholder="Receipt No" class="input-one" v-model="selectedItem.receiptNumber" />
                        <input type="date" placeholder="Receipt Date" class="input-one" v-model="selectedItem.receiptPdfCreationDate" />
                        <button class="btn-one" @click="approve" v-if="!selectedItem.isReceiptPdfCreated">Approve</button>
                        <!-- <input type="text" placeholder="Decline Reason" class="input-one" v-model="selectedItem.description" />
            <button class="btn-one" @click="decline" v-if="selectedItem.moneyTransferStatus === MoneyTransferStatusEnum.Created">Decline</button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import { nanoid } from "nanoid";
import { StaticPhrases } from "@/types/StaticPhrases";
import { GetPayTrPaymentsRequest, GetPayTrPaymentsResult, GetPayTrPaymentsResultItem } from "@/types/SharedTypes";
import { ActionTypes } from "@/store/action-types";
import StringHelper from "@/types/StringHelper";

export default defineComponent({
    name: "PayTrPayments",
    data() {
        return {
            waiting: false,
            getPayTrPaymentsResult: {} as GetPayTrPaymentsResult,
            selectedItem: null as GetPayTrPaymentsResultItem | null,
        };
    },
    mounted() {
        this.getPayTrPayments();
    },
    methods: {
        openDetails(item: GetPayTrPaymentsResultItem) {
            if (this.selectedItem == item) {
                this.selectedItem = null;
            } else {
                this.selectedItem = item;
            }
        },
        approve() {
            if (this.selectedItem === undefined || this.selectedItem === null || (this.selectedItem && Object.keys(this.selectedItem).length === 0 && this.selectedItem.constructor === Object)) {
                this.$store.dispatch(ActionTypes.TOASTERROR, "Seçili ödeme kaydı yok.");
                return;
            }
            if (this.selectedItem.receiptNumber === undefined || this.selectedItem.receiptNumber === null || this.selectedItem.receiptNumber.length === 0) {
                this.$store.dispatch(ActionTypes.TOASTERROR, "Fatura no girilmeli.");
                return;
            }
            if (this.selectedItem.receiptPdfCreationDate === undefined || this.selectedItem.receiptPdfCreationDate === null || this.selectedItem.receiptPdfCreationDate.length === 0 || this.selectedItem.receiptPdfCreationDate === "2010-01-01") {
                this.$store.dispatch(ActionTypes.TOASTERROR, "Fatura tarihi girilmeli.");
                return;
            }
            const fileElement = this.$refs.file as HTMLInputElement;
            if (fileElement !== undefined && fileElement !== null && fileElement.files !== undefined && fileElement.files !== null) {
                fileElement.click();
            }
        },
        handleFileUpload() {
            const fileElement = this.$refs.file as HTMLInputElement;
            if (fileElement !== undefined && fileElement !== null && fileElement.files !== undefined && fileElement.files !== null && fileElement.files.length > 0) {
                for (let i = 0; i < fileElement.files.length; i++) {
                    this.uploadFile(fileElement.files[i]);
                }
            }
        },
        uploadFile(file: File) {
            const formData = new FormData();
            if (this.selectedItem === undefined || this.selectedItem === null || (this.selectedItem && Object.keys(this.selectedItem).length === 0 && this.selectedItem.constructor === Object)) {
                this.$store.dispatch(ActionTypes.TOASTERROR, "Seçili ödeme kaydı yok.");
                return;
            }

            if (this.$store.getters.getLoginSessionId === undefined) {
                this.$store.dispatch(ActionTypes.TOASTERROR, "Oturum açılmalı.");
                return;
            }
            if (this.$store.getters.getIdToken === undefined) {
                this.$store.dispatch(ActionTypes.TOASTERROR, "Oturum açılmalı.");
                return;
            }

            const headers = {
                "Content-Type": "multipart/form-data",
                receiptid: this.selectedItem.receiptId,
                receiptnumber: this.selectedItem.receiptNumber,
                requestid: nanoid(),
                loginsessionid: this.$store.getters.getLoginSessionId,
                Authorization: "Bearer " + this.$store.getters.getIdToken,
            };

            formData.append("file", file);

            axios
                .post(this.$store.getters.getApiUrl + "admin/uploadReceipt", formData, {
                    headers: headers,
                    onUploadProgress: () => {
                        // file.uploadPercentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    },
                })
                .then((result) => {
                    if (result.data !== undefined && result.data !== null) {
                        const apiResult = result.data as any;
                        if (apiResult !== undefined && apiResult !== null) {
                            if (apiResult.error !== undefined && apiResult.error !== null) {
                                this.$store.dispatch(ActionTypes.TOASTERROR, apiResult.error);
                            } else {
                                this.$store.dispatch(ActionTypes.TOASTSUCCESS, "Fatura yüklendi.");
                            }
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTERROR, "Yükleme sonucu geçersiz.");
                        }
                    } else {
                        this.$store.dispatch(ActionTypes.TOASTERROR, "Yükleme sonucu geçersiz bir değer.");
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error", error);
                        if (error.response.status === 401) {
                            this.$store.dispatch(ActionTypes.TOASTERROR, "Yetkisiz erişim.");
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTERROR, error.response);
                        }
                    } else if (error.request) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, StaticPhrases.serverNotFoundError);
                    } else {
                        this.$store.dispatch(ActionTypes.TOASTERROR, error.message);
                    }
                })
                .finally(() => {
                    //
                });
        },
        getPayTrPayments() {
            this.waiting = true;
            const yourConfig = {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.getIdToken,
                },
            };
            const req = {
                loginSessionId: this.$store.getters.getLoginSessionId,
                requestId: nanoid(),
            } as GetPayTrPaymentsRequest;
            axios
                .post(this.$store.getters.getApiUrl + "admin/getPayTrPayments", req, yourConfig)
                .then((e) => {
                    if (e.data.error !== null) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, e.data.error);
                    } else {
                        if (e.data.result !== undefined && e.data.result !== null && e.data.result) {
                            this.getPayTrPaymentsResult = e.data.result;
                            this.getPayTrPaymentsResult.items?.forEach((x) => {
                                if (x.receiptPdfCreationDate) {
                                    const date = new Date(x.receiptPdfCreationDate);
                                    x.receiptPdfCreationDate = date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
                                }
                            });
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTWARNING, "Kayıt bulunamadı.");
                        }
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error", error);
                        if (error.response.status === 401) {
                            this.$store.dispatch(ActionTypes.TOASTERROR, "Yetkisiz erişim.");
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTERROR, error.response);
                        }
                    } else if (error.request) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, StaticPhrases.serverNotFoundError);
                    } else {
                        this.$store.dispatch(ActionTypes.TOASTERROR, error.message);
                    }
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        formatDecimal(amount: number, fractionDigits = 2): string {
            return StringHelper.formatDecimal(amount, fractionDigits);
        },
        formatDateLong(inputDate: string): string {
            return StringHelper.formatDateLong(inputDate);
        },
    },
});
</script>
