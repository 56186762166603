<template>
    <div class="mt-8">
        <div class="text-xl px-4 py-2 text-gray-400">Money Transfers</div>

        <div class="flex items-center bg-gray-100 mx-4 my-2 px-4 py-2">
            <div v-if="moneyTransferStatuses">
                <div class="text-sm font-semibold">Status</div>

                <select class="mt-1 outline-none block rounded px-2 py-1 ring-1 ring-gray-300 focus:ring-2 text-sm" v-model="selectedMoneyTransferStatus">
                    <option v-for="enumItem in moneyTransferStatuses" :key="enumItem.key" :value="enumItem.key">{{ enumItem.name }}</option>
                </select>
            </div>

            <div class="flex-grow"></div>

            <div>
                <button class="btn-one" @click="getReceiptToGenerate">List</button>
            </div>
        </div>

        <input type="file" id="file" ref="file" multiple v-on:change="handleFileUpload()" style="position: absolute; top: -500px" accept="application/pdf" />

        <div class="flex flex-col space-y-2 w-full p-8">
            <table class="table-auto" v-if="items">
                <thead>
                    <tr>
                        <th class="text-center">Id</th>

                        <th class="text-center">Users e-Mail</th>

                        <th class="text-center">Users Name</th>

                        <th class="text-right">Amount</th>

                        <th class="text-right">Created</th>

                        <th class="text-center">Package</th>

                        <th></th>

                        <th></th>

                        <th></th>

                        <th></th>

                        <th class="text-center">Status</th>

                        <th></th>

                        <th class="text-center">Actions</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="item in items" :key="item.moneyTransferId">
                        <td class="text-center">{{ item.moneyTransferId }}</td>

                        <td class="text-center">{{ item.userEMail }}</td>

                        <td class="text-center">{{ item.userDisplayName }}</td>

                        <td class="text-right">{{ formatDecimal(item.price) + " TL + " + formatDecimal(item.priceKdv) + " TL KDV" }}</td>

                        <td class="text-right">{{ item.moneyTransferCreatedDate }}</td>

                        <td class="text-center">{{ item.purchasedPackageName ? item.purchasedPackageName : item.userLicenseCount + " Licenses" }}</td>

                        <td></td>

                        <td></td>

                        <td></td>

                        <td></td>

                        <td class="text-center">{{ item.moneyTransferStatusString }}</td>

                        <td></td>

                        <td class="text-center">
                            <button class="btn-one" @click="openDetails(item)">Details</button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div v-else>No Record.</div>

            <div class="p-5"></div>

            <div v-if="selectedItem" class="flex space-x-6 border border-gray-200">
                <table class="table-auto m-4">
                    <tbody>
                        <tr>
                            <td class="text-right font-medium">Id</td>

                            <td class="text-left pl-4">{{ selectedItem.moneyTransferId }}</td>
                        </tr>

                        <tr>
                            <td class="text-right font-medium">Transfer Code</td>

                            <td class="text-left pl-4">{{ selectedItem.transferCode }}</td>
                        </tr>

                        <tr>
                            <td class="text-right font-medium">Company</td>

                            <td class="text-left pl-4">{{ selectedItem.receiptAddressCompanyName }}</td>
                        </tr>

                        <tr>
                            <td class="text-right font-medium">Company Tax No</td>

                            <td class="text-left pl-4">{{ selectedItem.receiptAddressCompanyTaxNo }}</td>
                        </tr>

                        <tr>
                            <td class="text-right font-medium">Company Tax Office</td>

                            <td class="text-left pl-4">{{ selectedItem.receiptAddressCompanyTaxOffice }}</td>
                        </tr>

                        <tr>
                            <td class="text-right font-medium">Citizenship No</td>

                            <td class="text-left pl-4">{{ selectedItem.receiptAddressCitizenshipNo }}</td>
                        </tr>

                        <tr>
                            <td class="text-right font-medium">City</td>

                            <td class="text-left pl-4">{{ selectedItem.receiptAddressCityName }}</td>
                        </tr>

                        <tr>
                            <td class="text-right font-medium">Country</td>

                            <td class="text-left pl-4">{{ selectedItem.receiptAddressCountry }}</td>
                        </tr>

                        <tr>
                            <td class="text-right font-medium">Address</td>

                            <td class="text-left pl-4">{{ selectedItem.receiptAddressAddress }}</td>
                        </tr>

                        <tr>
                            <td class="text-right font-medium">First Name</td>

                            <td class="text-left pl-4">{{ selectedItem.receiptAddressPersonFirstName }}</td>
                        </tr>

                        <tr>
                            <td class="text-right font-medium">Last Name</td>

                            <td class="text-left pl-4">{{ selectedItem.receiptAddressPersonLastName }}</td>
                        </tr>

                        <tr>
                            <td class="text-right font-medium">Zip Code</td>

                            <td class="text-left pl-4">{{ selectedItem.receiptAddressZipCode }}</td>
                        </tr>

                        <tr>
                            <td class="text-right font-medium">Price</td>

                            <td class="text-left pl-4">{{ formatDecimal(selectedItem.price) + " TL" }}</td>
                        </tr>

                        <tr>
                            <td class="text-right font-medium">KDV</td>

                            <td class="text-left pl-4">{{ formatDecimal(selectedItem.priceKdv) + " TL" }}</td>
                        </tr>

                        <tr>
                            <td class="text-right font-medium">Total</td>

                            <td class="text-left pl-4">{{ formatDecimal(selectedItem.price + selectedItem.priceKdv) + " TL" }}</td>
                        </tr>
                    </tbody>
                </table>

                <div class="flex space-x-2 border-l border-gray-200">
                    <div class="flex flex-col space-y-2 m-5">
                        <input type="text" placeholder="Receipt No" class="input-one" v-model="selectedItem.receiptNumber" />

                        <input type="date" placeholder="Receipt Date" class="input-one" v-model="selectedItem.receiptPdfCreationDate" />

                        <button class="btn-one" @click="approve" v-if="selectedItem.moneyTransferStatus === MoneyTransferStatusEnum.Created">Approve</button>

                        <input type="text" placeholder="Decline Reason" class="input-one" v-model="selectedItem.description" />

                        <button class="btn-one" @click="decline" v-if="selectedItem.moneyTransferStatus === MoneyTransferStatusEnum.Created">Decline</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import { nanoid } from "nanoid";
import { StaticPhrases } from "@/types/StaticPhrases";
import { EnumItem, GetMoneyTransfersRequest, GetMoneyTransfersResult, GetMoneyTransfersResultItem, MoneyTransferStatusEnum } from "@/types/SharedTypes";
import { ActionTypes } from "@/store/action-types";
import StringHelper from "@/types/StringHelper";

export default defineComponent({
    name: "MoneyTransfers",
    data() {
        return {
            items: null as Array<GetMoneyTransfersResultItem> | null,
            selectedItem: null as GetMoneyTransfersResultItem | null,
            moneyTransferStatuses: null as EnumItem[] | null,
            selectedMoneyTransferStatus: null as null | number,
            MoneyTransferStatusEnum: MoneyTransferStatusEnum,
        };
    },
    mounted() {
        this.getReceiptToGenerate();
    },
    methods: {
        openDetails(item: GetMoneyTransfersResultItem) {
            if (this.selectedItem == item) {
                this.selectedItem = null;
            } else {
                this.selectedItem = item;
            }
        },
        getReceiptToGenerate() {
            this.items = null;
            this.selectedItem = null;
            const yourConfig = {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.getIdToken,
                },
            };

            const req = {
                loginSessionId: this.$store.getters.getLoginSessionId,
                requestId: nanoid(),
                moneyTransferStatus: this.selectedMoneyTransferStatus,
            } as GetMoneyTransfersRequest;
            axios
                .post(this.$store.getters.getApiUrl + "admin/getMoneyTransfers", req, yourConfig)
                .then((e) => {
                    if (e.data.error !== null) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, e.data.error);
                    } else {
                        if (e.data.result !== undefined && e.data.result !== null && e.data.result.items !== undefined && e.data.result.items !== null && e.data.result.items.length > 0) {
                            this.items = e.data.result.items;
                            this.items?.forEach((x) => {
                                if (x.receiptPdfCreationDate) {
                                    const date = new Date(x.receiptPdfCreationDate);
                                    x.receiptPdfCreationDate = date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
                                }
                            });
                            if (!this.moneyTransferStatuses) this.moneyTransferStatuses = (e.data.result as GetMoneyTransfersResult).moneyTransferStatuses;
                        } else {
                            //this.$store.dispatch(ActionTypes.TOASTWARNING, "Kayıt bulunamadı.");
                        }
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error", error);
                        if (error.response.status === 401) {
                            this.$store.dispatch(ActionTypes.TOASTERROR, "Yetkisiz erişim.");
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTERROR, error.response);
                        }
                    } else if (error.request) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, StaticPhrases.serverNotFoundError);
                    } else {
                        this.$store.dispatch(ActionTypes.TOASTERROR, error.message);
                    }
                })
                .finally(() => {
                    //
                });
        },
        approve() {
            if (this.selectedItem === undefined || this.selectedItem === null || (this.selectedItem && Object.keys(this.selectedItem).length === 0 && this.selectedItem.constructor === Object)) {
                this.$store.dispatch(ActionTypes.TOASTERROR, "Seçili havale kaydı yok.");
                return;
            }
            if (this.selectedItem.receiptNumber === undefined || this.selectedItem.receiptNumber === null || this.selectedItem.receiptNumber.length === 0) {
                this.$store.dispatch(ActionTypes.TOASTERROR, "Fatura no girilmeli.");
                return;
            }
            if (this.selectedItem.receiptPdfCreationDate === undefined || this.selectedItem.receiptPdfCreationDate === null || this.selectedItem.receiptPdfCreationDate.length === 0 || this.selectedItem.receiptPdfCreationDate === "2010-01-01") {
                this.$store.dispatch(ActionTypes.TOASTERROR, "Fatura tarihi girilmeli.");
                return;
            }
            const fileElement = this.$refs.file as HTMLInputElement;
            if (fileElement !== undefined && fileElement !== null && fileElement.files !== undefined && fileElement.files !== null) {
                fileElement.click();
            }
        },
        decline() {
            if (this.selectedItem === undefined || this.selectedItem === null || (this.selectedItem && Object.keys(this.selectedItem).length === 0 && this.selectedItem.constructor === Object)) {
                this.$store.dispatch(ActionTypes.TOASTERROR, "Seçili havale kaydı yok.");
                return;
            }
            if (this.selectedItem.description === undefined || this.selectedItem.description === null || this.selectedItem.description.length === 0) {
                this.$store.dispatch(ActionTypes.TOASTERROR, "Red nedeni girilmeli.");
                return;
            }
        },
        handleFileUpload() {
            const fileElement = this.$refs.file as HTMLInputElement;
            if (fileElement !== undefined && fileElement !== null && fileElement.files !== undefined && fileElement.files !== null && fileElement.files.length > 0) {
                for (let i = 0; i < fileElement.files.length; i++) {
                    this.uploadFile(fileElement.files[i]);
                }
            }
        },
        uploadFile(file: File) {
            const formData = new FormData();
            if (this.selectedItem === undefined || this.selectedItem === null || (this.selectedItem && Object.keys(this.selectedItem).length === 0 && this.selectedItem.constructor === Object)) {
                this.$store.dispatch(ActionTypes.TOASTERROR, "Seçili havale kaydı yok.");
                return;
            }

            if (this.$store.getters.getLoginSessionId === undefined) {
                this.$store.dispatch(ActionTypes.TOASTERROR, "Oturum açılmalı.");
                return;
            }
            if (this.$store.getters.getIdToken === undefined) {
                this.$store.dispatch(ActionTypes.TOASTERROR, "Oturum açılmalı.");
                return;
            }

            const headers = {
                "Content-Type": "multipart/form-data",
                moneytransferid: this.selectedItem.moneyTransferId,
                receiptdate: this.selectedItem.receiptPdfCreationDate || "",
                receiptnumber: this.selectedItem.receiptNumber,
                requestid: nanoid(),
                loginsessionid: this.$store.getters.getLoginSessionId,
                Authorization: "Bearer " + this.$store.getters.getIdToken,
            };

            formData.append("file", file);

            axios
                .post(this.$store.getters.getApiUrl + "admin/approveMoneyTransfer", formData, {
                    headers: headers,
                    onUploadProgress: () => {
                        // file.uploadPercentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    },
                })
                .then((result) => {
                    if (result.data !== undefined && result.data !== null) {
                        const apiResult = result.data as any;
                        if (apiResult !== undefined && apiResult !== null) {
                            if (apiResult.error !== undefined && apiResult.error !== null) {
                                this.$store.dispatch(ActionTypes.TOASTERROR, apiResult.error);
                            } else {
                                this.$store.dispatch(ActionTypes.TOASTSUCCESS, "Fatura yüklendi.");
                            }
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTERROR, "Yükleme sonucu geçersiz.");
                        }
                    } else {
                        this.$store.dispatch(ActionTypes.TOASTERROR, "Yükleme sonucu geçersiz bir değer.");
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error", error);
                        if (error.response.status === 401) {
                            this.$store.dispatch(ActionTypes.TOASTERROR, "Yetkisiz erişim.");
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTERROR, error.response);
                        }
                    } else if (error.request) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, StaticPhrases.serverNotFoundError);
                    } else {
                        this.$store.dispatch(ActionTypes.TOASTERROR, error.message);
                    }
                })
                .finally(() => {
                    //
                });
        },
        formatCurrency(inputNumber: number | undefined, currency: string | undefined): string {
            return StringHelper.formatCurrency(inputNumber, currency);
        },
        formatDecimal(amount: number, fractionDigits = 2): string {
            return StringHelper.formatDecimal(amount, fractionDigits);
        },
    },
});
</script>
