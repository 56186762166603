<template>
    <div>
        <div class="sm:flex sm:items-center">
            <div class="sm:flex-auto">
                <h1 class="text-2xl font-semibold leading-6 text-gray-900">Flow count</h1>
                <p class="mt-2 text-sm text-gray-700">Seçilen süreç duruma göre en çok süreç oluşturan kullanıcıları
                    gösterir.
                </p>
            </div>
            <!-- <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                <button type="button"
                    class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Export</button>
            </div> -->
        </div>
        <div class="mt-10">
            <Listbox as="div" v-model="selectedFlowStatus">
                <ListboxLabel class="block text-sm font-medium leading-6 text-gray-900">Süreç durumu</ListboxLabel>
                <div class="relative mt-2">
                    <ListboxButton
                        class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                        <span class="block truncate">{{ selectedFlowStatus.displayString }}</span>

                    </ListboxButton>

                    <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
                        leave-to-class="opacity-0">
                        <ListboxOptions
                            class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            <ListboxOption as="template" v-for="person in flowStatusEnumItems" :key="person.value"
                                :value="person" v-slot="{ active, selected }">
                                <li
                                    :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{
                                        person.displayString }}</span>


                                </li>
                            </ListboxOption>
                        </ListboxOptions>
                    </transition>
                </div>
            </Listbox>

        </div>
        <div class="mt-8 flow-root">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table class="min-w-full divide-y divide-gray-300"
                        v-if="reportUserByFlowCountResult && reportUserByFlowCountResult.items">
                        <thead>
                            <tr>
                                <th scope="col"
                                    class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                    e-Mail</th>
                                <th scope="col"
                                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Flow count</th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200 bg-white">
                            <tr v-for="item in reportUserByFlowCountResult.items" :key="item.eMail">
                                <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{{ item.eMail
                                }}</td>
                                <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">{{
                                    item.flowCount }}</td>


                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ActionTypes } from "@/store/action-types";
import { FlowStatusEnum, ReportUserByFlowCountRequest, ReportUserByFlowCountResult } from "@/types/SharedTypes";
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { StaticPhrases } from "@/types/StaticPhrases";
import StringHelper from "@/types/StringHelper";
import axios from "axios";
import { nanoid } from "nanoid";
import { defineComponent } from "vue";

interface EnumItem {
    displayString: string,
    value: number
}

const enumItems: Array<EnumItem> = Object.entries(FlowStatusEnum).filter(x => typeof x[1] == 'number').map((x) => { return { displayString: x[0], value: x[1] as FlowStatusEnum }; })


export default defineComponent({
    name: "ReportUserByFlowCount",
    components: {
        Listbox,
        ListboxButton,
        ListboxLabel,
        ListboxOption,
        ListboxOptions,
    },
    data() {
        return {
            flowStatusEnumItems: enumItems,
            selectedFlowStatus: enumItems[0],
            reportUserByFlowCountResult: null as null | ReportUserByFlowCountResult
        };
    },
    mounted() {
        // this.reportUserByFlowCount();
        console.log("Object.entries(FlowStatusEnum)", Object.entries(FlowStatusEnum).filter(x => typeof x[1] == 'number').map((x) => { return { displayString: x[0], value: x[1] as FlowStatusEnum }; }));
    },
    methods: {
        reportUserByFlowCount(flowStatusEnum: FlowStatusEnum) {
            const yourConfig = {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.getIdToken,
                },
            };
            const req = {
                loginSessionId: this.$store.getters.getLoginSessionId,
                requestId: nanoid(),
                flowStatusEnum: flowStatusEnum
            } as ReportUserByFlowCountRequest;
            axios
                .post(this.$store.getters.getApiUrl + "admin/reportUserByFlowCount", req, yourConfig)
                .then((e) => {
                    if (e.data.error !== null) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, e.data.error);
                    } else {
                        const result = e.data.result as ReportUserByFlowCountResult;
                        this.reportUserByFlowCountResult = result;
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error", error);
                        if (error.response.status === 401) {
                            this.$store.dispatch(ActionTypes.TOASTERROR, "Yetkisiz erişim.");
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTERROR, error.response);
                        }
                    } else if (error.request) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, StaticPhrases.serverNotFoundError);
                    } else {
                        this.$store.dispatch(ActionTypes.TOASTERROR, error.message);
                    }
                })
                .finally(() => {
                    //
                });
        },
        formatDateLong(inputDate: string | null): string {
            if (inputDate) return StringHelper.formatDateLong(inputDate);
            return "";
        },
    },
    watch: {
        selectedFlowStatus: {
            handler(newValue: EnumItem) {
                this.reportUserByFlowCount(newValue.value);
            },
            immediate: true
        }
    }


});

</script>