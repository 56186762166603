import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";

import "./assets/styles/tailwind.css";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import Toast, { PluginOptions, POSITION } from "vue-toastification";
import { QuillEditor } from "@vueup/vue-quill";
import "vue-toastification/dist/index.css";
import i18n from "./i18n";

const options: PluginOptions = {
    position: POSITION.BOTTOM_CENTER,
    filterBeforeCreate: (toast, toasts) => {
        if (toasts.filter((t) => t.type === toast.type && t.content === toast.content).length !== 0) {
            // Returning false discards the toast
            return false;
        }
        // You can modify the toast if you want
        return toast;
    },
};

const app = createApp(App);

app.component("QuillEditor", QuillEditor);

app.use(i18n);

app.use(Toast, options);

app.use(store).use(router).mount("#app");
