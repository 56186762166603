<template>
    <div class="flex flex-col h-full">
        <div class="px-4 py-4 bg-gray-200 flex flex-row">
            <img class="h-8 ml-5 mr-10" src="@/assets/images/onaylarimlogo.png" />

            <router-link to="/" v-slot="{ isActive }">
                <div class="w-40 text-center mx-2 py-2 text-sm font-medium focus:outline-none cursor-pointer"
                    :class="[isActive ? 'text-gray-800 bg-white' : 'text-gray-400 bg-gray-100 hover:text-gray-900 focus:text-gray-900']">
                    Login</div>
            </router-link>
            <router-link to="/receipts" v-slot="{ isActive }">
                <div class="w-40 text-center mx-2 py-2 text-sm font-medium focus:outline-none cursor-pointer"
                    :class="[isActive ? 'text-gray-800 bg-white' : 'text-gray-400 bg-gray-100 hover:text-gray-900 focus:text-gray-900']">
                    Receipts</div>
            </router-link>
            <router-link to="/moneyTransfers" v-slot="{ isActive }">
                <div class="w-40 text-center mx-2 py-2 text-sm font-medium focus:outline-none cursor-pointer"
                    :class="[isActive ? 'text-gray-800 bg-white' : 'text-gray-400 bg-gray-100 hover:text-gray-900 focus:text-gray-900']">
                    Money Transfers</div>
            </router-link>
            <router-link to="/payTrPayments" v-slot="{ isActive }">
                <div class="w-40 text-center mx-2 py-2 text-sm font-medium focus:outline-none cursor-pointer"
                    :class="[isActive ? 'text-gray-800 bg-white' : 'text-gray-400 bg-gray-100 hover:text-gray-900 focus:text-gray-900']">
                    PayTR Payments</div>
            </router-link>
            <router-link to="/organizationsList" v-slot="{ isActive }">
                <div class="w-40 text-center mx-2 py-2 text-sm font-medium focus:outline-none cursor-pointer"
                    :class="[isActive ? 'text-gray-800 bg-white' : 'text-gray-400 bg-gray-100 hover:text-gray-900 focus:text-gray-900']">
                    Organizations</div>
            </router-link>
            <router-link to="/reportsView" v-slot="{ isActive }">
                <div class="w-40 text-center mx-2 py-2 text-sm font-medium focus:outline-none cursor-pointer"
                    :class="[isActive ? 'text-gray-800 bg-white' : 'text-gray-400 bg-gray-100 hover:text-gray-900 focus:text-gray-900']">
                    Reports</div>
            </router-link>
        </div>
        <div class="w-full flex-grow overflow-y-auto">
            <router-view class="w-full h-full" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MutationTypes } from "./store/mutation-types";

export default defineComponent({
    name: "App",
    mounted() {
        console.log("mounted");
        if ((process.env.VUE_APP_MODE || "").indexOf("Production") >= 0 || (process.env.VUE_APP_MODE || "").indexOf("Staging") >= 0) {
            let host = window.location.host;
            host = host.replace("admin", "api");
            this.$store.commit(MutationTypes.SET_APIURL, window.location.protocol + "//" + host + "/");
            console.log("apiURL", window.location.protocol + "//" + host + "/");
        } else {
            this.$store.commit(MutationTypes.SET_APIURL, process.env.VUE_APP_API_URL);
            console.log("apiURL", process.env.VUE_APP_API_URL);
        }
    },
});
</script>
