<template>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex">
        <!-- We've used 3xl here, but feel free to try other max-widths based on your needs -->
        <div class="max-w-xl w-96 mx-auto my-auto space-y-2">
            <div class="col-span-6 sm:col-span-3">
                <label for="email" class="block text-sm font-medium text-gray-700">e-Mail</label>
                <input v-model="email" type="text" name="email" id="email" class="mt-1 focus:ring-sky-500 focus:border-sky-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
            </div>
            <div class="col-span-6 sm:col-span-3">
                <label for="password" class="block text-sm font-medium text-gray-700">Password</label>
                <input v-model="password" type="password" name="password" id="password" class="mt-1 focus:ring-sky-500 focus:border-sky-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
            </div>

            <button
                type="button"
                tabindex="2"
                @click="login"
                class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 text-white bg-sky-600 disabled:bg-gray-600 hover:bg-sky-700 focus:ring-sky-500 shadow-sm"
            >
                <div class="flex">Login</div>
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import { nanoid } from "nanoid";
import { StaticPhrases } from "@/types/StaticPhrases";
import { SignInRequest, SignInUpResult } from "@/types/SharedTypes";
import { ActionTypes } from "@/store/action-types";
import { MutationTypes } from "@/store/mutation-types";

export class Cell {
    color!: string;
    x!: number;
    y!: number;
}
export default defineComponent({
    name: "LoginView",
    data() {
        return {
            password: "",
            email: "",
        };
    },

    methods: {
        login() {
            const req = {
                eMail: this.email,
                password: this.password,
                requestId: nanoid(),
            } as SignInRequest;
            axios
                .post(this.$store.getters.getApiUrl + "account/signIn", req)
                .then((e) => {
                    if (e.data.error !== null) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, e.data.error);
                    } else {
                        const signInResult = e.data.result as SignInUpResult;
                        this.$store.commit(MutationTypes.SET_IDTOKEN, signInResult.idToken);
                        this.$store.commit(MutationTypes.SET_LOGINSESSIONID, signInResult.loginSessionId);
                        this.$router.push("/receipts");
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, error.response.data);
                    } else if (error.request) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, StaticPhrases.serverNotFoundError);
                    } else {
                        this.$store.dispatch(ActionTypes.TOASTERROR, error.message);
                    }
                })
                .finally(() => {
                    //
                });
        },
    },
});
</script>
