<template>
    <div class="mx-auto max-w-7xl sm:p-6 lg:p-8">
        <Listbox as="div" v-model="selectedReport">
            <ListboxLabel class="block text-sm font-medium leading-6 text-gray-900">Select a report</ListboxLabel>
            <div class="relative mt-2">
                <ListboxButton
                    class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    <span class="block truncate">{{ selectedReport.name }}</span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
                    leave-to-class="opacity-0">
                    <ListboxOptions
                        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <ListboxOption as="template" v-for="person in reports" :key="person.id" :value="person"
                            v-slot="{ active, selected }">
                            <li
                                :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{ person.name
                                }}</span>
                            </li>
                        </ListboxOption>
                    </ListboxOptions>
                </transition>
            </div>
        </Listbox>
        <div class="mt-10">
            <component :is="userComponent"></component>
        </div>
        <!-- <ReportUserByLoginCount></ReportUserByLoginCount> -->
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import ReportUserByLoginCount from "@/components/ReportUserByLoginCount.vue";
import ReportUserByFlowCount from "@/components/ReportUserByFlowCount.vue";
import ReportUserLogin from "@/components/ReportUserLogin.vue";
import ReportUserDetails from "@/components/ReportUserDetails.vue";
import ReportNewRegistars from "@/components/ReportNewRegistars.vue";

// const ReportUserByLoginCount = () => import("@/components/ReportUserByLoginCount.vue");

const reportList = [
    { id: 1, name: 'Login count', component: "ReportUserByLoginCount" },
    { id: 2, name: 'Flow count', component: "ReportUserByFlowCount" },
    { id: 3, name: 'Logins', component: "ReportUserLogin" },
    { id: 4, name: 'User details', component: "ReportUserDetails" },
    { id: 5, name: 'New users', component: "ReportNewRegistars" },

]

export default defineComponent({
    name: "ReportsView",
    components: {
        Listbox,
        ListboxButton,
        ListboxLabel,
        ListboxOption,
        ListboxOptions,
        ReportUserByLoginCount,
        ReportUserByFlowCount,
        ReportUserLogin,
        ReportUserDetails,
        ReportNewRegistars
    },
    data() {
        return {
            reports: reportList,
            selectedReport: reportList[0]
        };
    },
    computed: {
        userComponent() {
            return this.selectedReport.component;
        }
    }

});

</script>