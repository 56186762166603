<template>
    <div class="mt-8">
        <div class="text-xl px-8 py-2 text-gray-400">Basılması Gereken Faturalar</div>

        <input type="file" id="file" ref="file" multiple v-on:change="handleFileUpload()" style="position: absolute; top: -500px" accept="application/pdf" />

        <div class="flex flex-col space-y-2 w-full p-8">
            <table class="table-auto" v-if="items">
                <thead>
                    <tr>
                        <th class="text-center">Id</th>

                        <th class="text-center">Users e-Mail</th>

                        <th class="text-center">Users Name</th>

                        <th class="text-right">Amount</th>

                        <th class="text-right">Created</th>

                        <th class="text-center">Package</th>

                        <th></th>

                        <th></th>

                        <th></th>

                        <th></th>

                        <th class="text-center">Status</th>

                        <th></th>

                        <th class="text-center">Actions</th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="item in items" :key="item.receiptId">
                        <td class="text-center">{{ item.receiptId }}</td>

                        <td class="text-center">{{ item.receiptAddressPersonFirstName }}</td>

                        <td class="text-center">{{ item.receiptAddressPersonLastName }}</td>

                        <td class="text-right">{{ item.price }}</td>

                        <td class="text-right">{{ item.receiptCreatedDate }}</td>

                        <td class="text-center">{{ item.receiptType }}</td>

                        <td></td>

                        <td></td>

                        <td></td>

                        <td></td>

                        <td class="text-center">{{ item.receiptNumber }}</td>

                        <td></td>

                        <td class="text-center">
                            <button class="btn-one">Details</button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div v-else>No Record.</div>

            <div class="p-5"></div>
        </div>

        <!-- old start -->

        <div class="flex flex-col space-y-2 w-full p-8">
            <div v-for="item in items" :key="item.receiptId" class="bg-gray-100 p-4 space-y-4">
                <div class="flex flex-row flex-wrap">
                    <div
                        v-for="column in columns"
                        :key="column"
                        :class="[item[column] !== undefined && item[column] !== null && item[column].length > 0 ? 'w-1/6' : '']"
                        v-show="dontShowColumns.some((x) => x === column) === false && item[column] !== undefined && item[column] !== null && item[column].length > 0"
                    >
                        <div class="p-2">
                            <div class="text-xl text-gray-500">{{ column }}</div>

                            <div class="text-sm">{{ item[column] || "BOŞ" }}</div>
                        </div>
                    </div>
                </div>

                <input type="text" v-model="item.receiptNumber" placeholder="Fatura no" class="border border-blue-300 outline-none focus:outline-none px-1 py-1 mr-2" />

                <button class="outline-none focus:outline-none bg-blue-400 my-auto px-2 py-1 rounded text-white" @click="addFile(item)">Fatura Ekle</button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import axios from "axios";
import { nanoid } from "nanoid";
import { StaticPhrases } from "@/types/StaticPhrases";
import { GetReceiptToGenerateRequest, GetReceiptToGenerateResultItem } from "@/types/SharedTypes";
import { ActionTypes } from "@/store/action-types";

export default defineComponent({
    name: "ReceiptsView",
    data() {
        return {
            selectedReceipt: undefined as undefined | GetReceiptToGenerateResultItem,
            columns: [] as Array<string>,
            items: [] as Array<GetReceiptToGenerateResultItem>,
            dontShowColumns: [
                "receipts_ReceiptAddressId",
                "receipts_ReceiptDate",
                "userCreditTransactions_FlowId",
                "userCreditTransactions_CreatedDate",
                "userCreditTransactions_TransactionType",
                "moneyTransfers_CreatedDate",
                "iyzicoPaymentResults_CreatedDate",
                "iyzicoPaymentResults_CardToken",
                "iyzicoPaymentResults_BinNumber",
                "iyzicoPaymentResults_LastFourDigits",
                "iyzicoPaymentResults_PaymentItems",
            ],
        };
    },
    mounted() {
        this.getReceiptToGenerate();
    },
    methods: {
        getReceiptToGenerate() {
            const yourConfig = {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.getIdToken,
                },
            };
            const req = {
                loginSessionId: this.$store.getters.getLoginSessionId,
                requestId: nanoid(),
            } as GetReceiptToGenerateRequest;
            axios
                .post(this.$store.getters.getApiUrl + "admin/getReceiptToGenerate", req, yourConfig)
                .then((e) => {
                    if (e.data.error !== null) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, e.data.error);
                    } else {
                        if (e.data.result !== undefined && e.data.result !== null && e.data.result.items !== undefined && e.data.result.items !== null && e.data.result.items.length > 0) {
                            this.items = e.data.result.items;
                            this.columns = Object.keys(this.items[0]).map((key) => {
                                return key;
                            });
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTWARNING, "Fatura bulunamadı.");
                        }
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error", error);
                        if (error.response.status === 401) {
                            this.$store.dispatch(ActionTypes.TOASTERROR, "Yetkisiz erişim.");
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTERROR, error.response);
                        }
                    } else if (error.request) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, StaticPhrases.serverNotFoundError);
                    } else {
                        this.$store.dispatch(ActionTypes.TOASTERROR, error.message);
                    }
                })
                .finally(() => {
                    //
                });
        },
        addFile(receipt: GetReceiptToGenerateResultItem) {
            if (receipt === undefined || receipt === null) {
                this.$store.dispatch(ActionTypes.TOASTERROR, "Seçili fatura kaydı yok.");
                return;
            }
            if (receipt.receiptNumber === undefined || receipt.receiptNumber === null || receipt.receiptNumber.length === 0) {
                this.$store.dispatch(ActionTypes.TOASTERROR, "Fatura no girilmeli.");
                return;
            }
            const fileElement = this.$refs.file as HTMLInputElement;
            if (fileElement !== undefined && fileElement !== null && fileElement.files !== undefined && fileElement.files !== null) {
                this.selectedReceipt = receipt;
                fileElement.click();
            }
        },
        handleFileUpload() {
            const fileElement = this.$refs.file as HTMLInputElement;
            if (fileElement !== undefined && fileElement !== null && fileElement.files !== undefined && fileElement.files !== null && fileElement.files.length > 0) {
                for (let i = 0; i < fileElement.files.length; i++) {
                    this.uploadFile(fileElement.files[i]);
                }
            }
        },
        uploadFile(file: File) {
            const formData = new FormData();
            let receiptId = 0;
            let loginSessionId = "";
            let receiptNumber = "";
            let idToken = "";
            if (this.selectedReceipt !== undefined) {
                receiptId = this.selectedReceipt.receiptId;
                receiptNumber = this.selectedReceipt.receiptNumber;
            } else {
                //
                return;
            }

            if (this.$store.getters.getLoginSessionId !== undefined) {
                loginSessionId = this.$store.getters.getLoginSessionId;
            } else {
                //
                return;
            }
            if (this.$store.getters.getIdToken !== undefined) {
                idToken = this.$store.getters.getIdToken;
            } else {
                //
                return;
            }

            const headers = { "Content-Type": "multipart/form-data", receiptid: receiptId, receiptnumber: receiptNumber, requestid: nanoid(), loginsessionid: loginSessionId, Authorization: "Bearer " + idToken };

            formData.append("file", file);

            axios
                .post(this.$store.getters.getApiUrl + "admin/uploadReceipt", formData, {
                    headers: headers,
                    onUploadProgress: () => {
                        // file.uploadPercentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    },
                })
                .then((result) => {
                    if (result.data !== undefined && result.data !== null) {
                        const apiResult = result.data as any;
                        if (apiResult !== undefined && apiResult !== null) {
                            if (apiResult.error !== undefined && apiResult.error !== null) {
                                this.$store.dispatch(ActionTypes.TOASTERROR, apiResult.error);
                            } else {
                                this.$store.dispatch(ActionTypes.TOASTSUCCESS, "Fatura yüklendi.");
                            }
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTERROR, "Yükleme sonucu geçersiz.");
                        }
                    } else {
                        this.$store.dispatch(ActionTypes.TOASTERROR, "Yükleme sonucu geçersiz bir değer.");
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error", error);
                        if (error.response.status === 401) {
                            this.$store.dispatch(ActionTypes.TOASTERROR, "Yetkisiz erişim.");
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTERROR, error.response);
                        }
                    } else if (error.request) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, StaticPhrases.serverNotFoundError);
                    } else {
                        this.$store.dispatch(ActionTypes.TOASTERROR, error.message);
                    }
                })
                .finally(() => {
                    //
                });
        },
    },
});
</script>
