<template>
    <TransitionRoot as="template" :show="createSubscriptionUpgradeRequest != null">
        <Dialog as="template" class="fixed z-10 inset-0 overflow-y-auto" @close="createSubscriptionUpgradeRequest = null">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                    leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <TransitionChild as="template" enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div
                        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
                        <div v-if="createSubscriptionUpgradeRequest">
                            <div class="px-10 py-8 justify-self-start self-start">
                                <label class="block text-2xl text-gray-700">Plan Yükseltme Talebi Oluştur</label>
                                <label class="block text-xl text-gray-700 mb-4">{{
                                    getSubscriptionUpgradeUpgradeType(createSubscriptionUpgradeRequest.upgradeType)
                                }}</label>
                                <div class="pt-2">
                                    <label for="descOne" class="block text-sm font-medium text-gray-700">Talep
                                        açıklaması</label>
                                    <div class="mt-1">
                                        <textarea v-model="createSubscriptionUpgradeRequest.creatorNote" id="descOne"
                                            name="descOne" rows="3"
                                            class="outline-none focus:outline-none p-2 shadow-sm focus:ring-sky-500 focus:border-sky-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md" />
                                    </div>
                                </div>
                                <div class="pt-4"
                                    v-if="createSubscriptionUpgradeRequest.upgradeType !== SubscriptionUpgradeUpgradeTypeEnum.UpgradeToNewPlan">
                                    <label for="newValue" class="block text-sm font-medium text-gray-700">Yeni değer</label>
                                    <div class="mt-1 w-20">
                                        <input id="newValue" v-model="createSubscriptionUpgradeRequest.newValue"
                                            name="newValue" autocomplete="off"
                                            class="outline-none focus:outline-none p-2 shadow-sm focus:ring-sky-500 focus:border-sky-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md" />
                                    </div>
                                </div>
                                <div class="pt-2" v-else>
                                    <label for="newValue" class="block text-sm font-medium text-gray-700">Yeni plan</label>
                                    <div class="mt-1">
                                        <select id="selected-tab" name="selected-tab"
                                            v-model="createSubscriptionUpgradeRequest.newValue"
                                            class="shadow-sm focus:ring-sky-500 focus:border-sky-500 block w-full sm:text-sm border px-2 py-2 border-gray-300 rounded-md">
                                            <option v-for="plan in plans" :key="plan.name" :value="plan.name">{{
                                                plan.displayNameForWeb + " - " + plan.name }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="pt-2 mt-10">
                                    <button @click="planUpgrade()" type="button" :disabled="isWorking"
                                        class="inline-flex items-center justify-center px-4 py-1 text-sm border border-transparent font-medium rounded-md shadow-sm text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 disabled:text-gray-200 disabled:bg-gray-400">
                                        Talep Oluştur
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
    <TransitionRoot as="template" :show="approveOrDenySubscriptionUpgradeRequest != null">
        <Dialog as="template" class="fixed z-10 inset-0 overflow-y-auto"
            @close="approveOrDenySubscriptionUpgradeRequest = null">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                    leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <TransitionChild as="template" enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div
                        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
                        <div v-if="approveOrDenySubscriptionUpgradeRequest">
                            <div class="px-10 py-8 justify-self-start self-start">
                                <label class="block text-2xl text-gray-700 mb-4">Yükseltme Talebi Onay/Red</label>
                                <div>Talep Kodu: {{ approveOrDenySubscriptionUpgradeRequest.planUpgradeRequestId }}</div>
                                <div>Organizasyon Id: {{ approveOrDenySubscriptionUpgradeRequest.organizationId }}</div>
                                <div>Üyelik Id: {{ approveOrDenySubscriptionUpgradeRequest.subscriptionId }}</div>
                                <div class="pt-2">
                                    <label for="descOne" class="block text-sm font-medium text-gray-700">Onay/Red
                                        notu</label>
                                    <div class="mt-1">
                                        <textarea v-model="approveOrDenySubscriptionUpgradeRequest.approverNote"
                                            id="descOne" name="descOne" rows="3"
                                            class="outline-none focus:outline-none p-2 shadow-sm focus:ring-sky-500 focus:border-sky-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md" />
                                    </div>
                                </div>
                                <div class="pt-2">
                                    <label for="okjlk" class="block text-sm font-medium text-gray-700">Şifre</label>
                                    <div class="mt-1">
                                        <input v-model="approveOrDenySubscriptionUpgradeRequest.passwordForOperation"
                                            autocomplete="off" id="okjlk" name="okjlk"
                                            class="outline-none focus:outline-none p-2 shadow-sm focus:ring-sky-500 focus:border-sky-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md" />
                                    </div>
                                </div>
                                <div class="pt-2 space-x-2 mt-10">
                                    <button type="button" :disabled="isWorking"
                                        @click="ApproveOrDenySubscriptionUpgrade(true)"
                                        class="inline-flex items-center justify-center px-4 py-1 text-sm border border-transparent font-medium rounded-md shadow-sm text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500 disabled:text-gray-200 disabled:bg-gray-400">
                                        Yükseltmeyi Onayla
                                    </button>
                                    <button type="button" :disabled="isWorking"
                                        @click="ApproveOrDenySubscriptionUpgrade(false)"
                                        class="inline-flex items-center justify-center px-4 py-1 text-sm border border-transparent font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:text-gray-200 disabled:bg-gray-400">
                                        Reddet
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
    <!-- <TransitionRoot as="template" :show="allSettings != null">
        <Dialog as="template" class="fixed z-10 inset-0 overflow-hidden" @close="allSettings = null">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

              
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div style="max-height: 70%" class="text-sm inline-block align-bottom bg-white rounded-lg text-left overflow-y-auto shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
                        <div v-if="allSettings">
                            <div class="p-4 justify-self-start self-start">
                                <div v-for="(setting, index) in allSettings" :key="index">{{ setting }}</div>
                            </div>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot> -->

    <div class="flex flex-col">
        <div class="grid grid-cols-7 gap-6 mb-6 bg-gray-50 py-4 px-4 border border-gray-200 max-w-5xl mx-auto mt-2">
            <div class="col-span-7 sm:col-span-2">
                <label for="xyz1" class="block text-sm font-medium text-gray-700">Organizasyon</label>
                <input v-model="organizationFilter" type="text" name="xyz1" id="xyz1" autocomplete="off"
                    class="mt-1 focus:ring-sky-500 focus:border-sky-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                <div class="text-xs text-gray-400 mt-1">Organizasyonun adından 'içinde geçen' şeklinde arama yapılır</div>
            </div>

            <div class="col-span-7 sm:col-span-2">
                <label for="osman1" class="block text-sm font-medium text-gray-700">e-Posta</label>

                <input v-model="eMailFilter" type="text" name="osman1" id="osman1" autocomplete="off"
                    class="mt-1 focus:ring-sky-500 focus:border-sky-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                <div class="text-xs text-gray-400 mt-1">Birden çok e-posta adresi aralarında boşluk bırakılarak girilebilir
                </div>
                <div class="text-xs text-gray-400 mt-1">Tek bir e-posta adresi aranacaksa 'içinde geçen' şeklinde arama
                    yapılır</div>
            </div>

            <div class="col-span-7 sm:col-span-2">
                <label for="mehmet2" class="block text-sm font-medium text-gray-700">Plan</label>
                <input v-model="planFilter" type="text" name="mehmet2" id="mehmet2" autocomplete="off"
                    class="mt-1 focus:ring-sky-500 focus:border-sky-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                <div class="text-xs text-gray-400 mt-1">Plan adından 'içinde geçen' şeklinde arama yapılır</div>
            </div>
            <div class="col-span-7 sm:col-span-1">
                <label class="block text-sm font-medium text-transparent">.</label>
                <button @click="getOrganizations" :disabled="isWorking"
                    class="mt-1 w-full flex justify-center py-2 px-4 border border-transparent rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 text-white bg-sky-600 disabled:bg-gray-600 hover:bg-sky-700 focus:ring-sky-500 shadow-sm">
                    <span class="">Ara</span>
                </button>
            </div>
        </div>
        <div class="overflow-x-auto" v-if="organizations">
            <div class="align-middle inline-block min-w-full">
                <div class="shadow overflow-hidden border-b border-cyan-200">
                    <table class="min-w-full divide-y divide-cyan-200">
                        <thead class="bg-cyan-400">
                            <tr>
                                <th scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider flex items-center space-x-2">
                                    <div>Id</div>
                                    <div class="w-4 h-4 border"
                                        :class="[isAllSelected ? 'bg-green-600 border-gray-200' : ' bg-white border-gray-500']"
                                        @click.stop="selectAll()"></div>
                                </th>
                                <th scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Organization Name</th>
                                <th scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Owner User</th>
                                <th scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Plan</th>

                                <th scope="col"
                                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Created Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(organization, index) in organizations" :key="organization.id">
                                <tr class="cursor-pointer" :class="index % 2 === 0 ? 'bg-cyan-50' : 'bg-cyan-100'"
                                    @click="organization.isExpanded = !organization.isExpanded">
                                    <td
                                        class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 flex items-center space-x-2">
                                        <div>{{ organization.id }}</div>
                                        <div class="w-4 h-4 border"
                                            :class="[organization.isSelected ? 'bg-green-600 border-gray-200' : ' bg-white border-gray-500']"
                                            @click.stop="organization.isSelected = !organization.isSelected"></div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {{ organization.displayName }}
                                    </td>

                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        <div>
                                            <div class="font-medium">{{ organization.ownerUserEMail }}</div>
                                            <div class="-mt-1 text-xs opacity-70">
                                                {{ organization.ownerUserDisplayName }}
                                            </div>
                                        </div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        <div>
                                            <div class="font-medium">{{ getPlanName(organization.plan) }}</div>
                                            <div class="-mt-1 text-xs opacity-70">{{ organization.plan }}</div>
                                        </div>
                                    </td>
                                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {{ formatDateLong(organization.createdDate) }}
                                    </td>
                                </tr>
                                <tr v-if="organization.isExpanded" :class="index % 2 === 0 ? 'bg-cyan-50' : 'bg-cyan-100'">
                                    <td colspan="5" class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        <!-- Subscriptions start -->

                                        <div class="overflow-hidden mx-auto">
                                            <table
                                                class="divide-y divide-amber-200 text-xs border border-amber-200 sm:rounded-lg mx-auto">
                                                <thead class="bg-amber-400">
                                                    <tr>
                                                        <th scope="col"
                                                            class="px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider">
                                                            Id</th>
                                                        <th scope="col"
                                                            class="px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider">
                                                            Plan</th>
                                                        <th scope="col"
                                                            class="px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider">
                                                            Dates</th>
                                                        <th scope="col"
                                                            class="px-6 py-3 text-center font-medium text-gray-500 uppercase tracking-wider">
                                                            Free/Total License</th>
                                                        <th scope="col"
                                                            class="px-6 py-3 text-center font-medium text-gray-500 uppercase tracking-wider">
                                                            Eyp1</th>
                                                        <th scope="col"
                                                            class="px-6 py-3 text-center font-medium text-gray-500 uppercase tracking-wider">
                                                            KEP</th>
                                                        <th scope="col"
                                                            class="px-6 py-3 text-center font-medium text-gray-500 uppercase tracking-wider">
                                                            SFTP</th>
                                                        <th scope="col"
                                                            class="px-6 py-3 text-center font-medium text-gray-500 uppercase tracking-wider">
                                                            External Users</th>
                                                        <th scope="col"
                                                            class="px-6 py-3 text-center font-medium text-gray-500 uppercase tracking-wider">
                                                            Advanced Flow</th>
                                                        <th scope="col"
                                                            class="px-6 py-3 text-center font-medium text-gray-500 uppercase tracking-wider">
                                                            UIM</th>
                                                        <th scope="col"
                                                            class="px-6 py-3 text-center font-medium text-gray-500 uppercase tracking-wider">
                                                            SignHub API</th>
                                                        <th scope="col"
                                                            class="px-6 py-3 text-center font-medium text-gray-500 uppercase tracking-wider">
                                                            coreAPI</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <template v-for="(subscription, index1) in organization.subscriptions"
                                                        :key="subscription.id">
                                                        <tr :class="index1 % 2 === 0 ? 'bg-amber-50' : 'bg-amber-100'">
                                                            <td
                                                                class="px-6 py-4 whitespace-nowrap font-medium text-gray-900">
                                                                {{ subscription.id }}
                                                            </td>
                                                            <td class="px-6 py-4 whitespace-nowrap text-gray-500">
                                                                <div class="flex items-center space-x-1">
                                                                    <div :class="[subscription.isActive ? 'bg-green-500' : 'bg-red-500']"
                                                                        class="h-2 w-2 rounded-full opacity-50"></div>
                                                                    <div
                                                                        @click="openModalForCreateRequest(organization.id, subscription.id, SubscriptionUpgradeUpgradeTypeEnum.UpgradeToNewPlan, subscription.isActive)">
                                                                        <div>
                                                                            <div class="font-medium"
                                                                                :class="[subscription.isActive ? 'cursor-pointer text-sky-500 font-medium underline' : '']">
                                                                                {{ getPlanName(subscription.planName) }}
                                                                            </div>
                                                                            <div
                                                                                class="-mt-1 text-xs opacity-70 no-underline">
                                                                                {{ subscription.planName }}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td class="px-6 py-4 whitespace-nowrap text-gray-500">
                                                                <div>{{ formatDateLong(subscription.startDate) }}</div>
                                                                <div>{{ formatDateLong(subscription.endDate) }}</div>
                                                            </td>

                                                            <td
                                                                class="px-6 py-4 whitespace-nowrap text-gray-500 flex justify-center">
                                                                {{ subscription.freeLicenseCount }} /
                                                                <div @click="openModalForCreateRequest(organization.id, subscription.id, SubscriptionUpgradeUpgradeTypeEnum.TotalLicenseCount, subscription.isActive)"
                                                                    :class="[subscription.isActive ? 'cursor-pointer text-sky-500 font-medium underline' : '']">
                                                                    {{ subscription.totalLicenseCount }}
                                                                </div>
                                                            </td>
                                                            <td
                                                                class="px-6 py-4 whitespace-nowrap text-gray-500 text-center">
                                                                <div @click="openModalForCreateRequest(organization.id, subscription.id, SubscriptionUpgradeUpgradeTypeEnum.CanCreateEyp1, subscription.isActive)"
                                                                    :class="[subscription.isActive ? 'cursor-pointer text-sky-500 font-medium underline' : '']">
                                                                    {{ subscription.canCreateEyp1 }}
                                                                </div>
                                                            </td>
                                                            <td
                                                                class="px-6 py-4 whitespace-nowrap text-gray-500 text-center">
                                                                <div @click="openModalForCreateRequest(organization.id, subscription.id, SubscriptionUpgradeUpgradeTypeEnum.CanSendKEP, subscription.isActive)"
                                                                    :class="[subscription.isActive ? 'cursor-pointer text-sky-500 font-medium underline' : '']">
                                                                    {{ subscription.canSendKEP }}
                                                                </div>
                                                            </td>
                                                            <td
                                                                class="px-6 py-4 whitespace-nowrap text-gray-500 text-center">
                                                                <div @click="openModalForCreateRequest(organization.id, subscription.id, SubscriptionUpgradeUpgradeTypeEnum.CanSendSFTP, subscription.isActive)"
                                                                    :class="[subscription.isActive ? 'cursor-pointer text-sky-500 font-medium underline' : '']">
                                                                    {{ subscription.canSendSFTP }}
                                                                </div>
                                                            </td>
                                                            <td
                                                                class="px-6 py-4 whitespace-nowrap text-gray-500 text-center">
                                                                <div @click="openModalForCreateRequest(organization.id, subscription.id, SubscriptionUpgradeUpgradeTypeEnum.CanSendToExternalUsers, subscription.isActive)"
                                                                    :class="[subscription.isActive ? 'cursor-pointer text-sky-500 font-medium underline' : '']">
                                                                    {{ subscription.canSendToExternalUsers }}
                                                                </div>
                                                            </td>

                                                            <td
                                                                class="px-6 py-4 whitespace-nowrap text-gray-500 text-center">
                                                                <div @click="openModalForCreateRequest(organization.id, subscription.id, SubscriptionUpgradeUpgradeTypeEnum.CanUseAdvancedFlow, subscription.isActive)"
                                                                    :class="[subscription.isActive ? 'cursor-pointer text-sky-500 font-medium underline' : '']">
                                                                    {{ subscription.canUseAdvancedFlow }}
                                                                </div>
                                                            </td>
                                                            <td
                                                                class="px-6 py-4 whitespace-nowrap text-gray-500 text-center">
                                                                <div @click="openModalForCreateRequest(organization.id, subscription.id, SubscriptionUpgradeUpgradeTypeEnum.CanUseUim, subscription.isActive)"
                                                                    :class="[subscription.isActive ? 'cursor-pointer text-sky-500 font-medium underline' : '']">
                                                                    {{ subscription.canUseUim }}
                                                                </div>
                                                            </td>
                                                            <td
                                                                class="px-6 py-4 whitespace-nowrap text-gray-500 text-center">
                                                                <div @click="openModalForCreateRequest(organization.id, subscription.id, SubscriptionUpgradeUpgradeTypeEnum.TotalApiLicenseCount, subscription.isActive)"
                                                                    :class="[subscription.isActive ? 'cursor-pointer text-sky-500 font-medium underline' : '']">
                                                                    {{ subscription.totalApiLicenseCount }}
                                                                </div>
                                                            </td>
                                                            <td
                                                                class="px-6 py-4 whitespace-nowrap text-gray-500 text-center">
                                                                <div @click="openModalForCreateRequest(organization.id, subscription.id, SubscriptionUpgradeUpgradeTypeEnum.TotalCoreApiLicenseCount, subscription.isActive)"
                                                                    :class="[subscription.isActive ? 'cursor-pointer text-sky-500 font-medium underline' : '']">
                                                                    {{ subscription.totalCoreApiLicenseCount }}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr v-if="subscription.subscriptionUpgrades && subscription.subscriptionUpgrades.length > 0"
                                                            :class="index1 % 2 === 0 ? 'bg-amber-50' : 'bg-amber-100'">
                                                            <td colspan="12"
                                                                class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                                <!-- start -->
                                                                <div class="overflow-hidden mx-auto">
                                                                    <table
                                                                        class="divide-y divide-lime-200 text-xs border border-lime-200 sm:rounded-lg mx-auto">
                                                                        <thead class="bg-lime-400">
                                                                            <tr>
                                                                                <th scope="col"
                                                                                    class="px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider">
                                                                                    Id</th>
                                                                                <th scope="col"
                                                                                    class="px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider">
                                                                                    Upgrade Id</th>
                                                                                <th scope="col"
                                                                                    class="px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider">
                                                                                    Status</th>

                                                                                <th scope="col"
                                                                                    class="px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider">
                                                                                    Upgrade Type</th>
                                                                                <th scope="col"
                                                                                    class="px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider">
                                                                                    New Value</th>
                                                                                <th scope="col"
                                                                                    class="px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider">
                                                                                    Creator User</th>
                                                                                <th scope="col"
                                                                                    class="px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider">
                                                                                    Creator Note</th>
                                                                                <th scope="col"
                                                                                    class="px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider">
                                                                                    Approved Date</th>
                                                                                <th scope="col"
                                                                                    class="px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider">
                                                                                    Approver</th>
                                                                                <th scope="col"
                                                                                    class="px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider">
                                                                                    Approver Note</th>

                                                                                <th scope="col"
                                                                                    class="px-6 py-3 text-left font-medium text-gray-500 uppercase tracking-wider">
                                                                                    Created Date</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <template
                                                                                v-for="(subscriptionUpgrade, index1) in subscription.subscriptionUpgrades"
                                                                                :key="subscriptionUpgrade.id">
                                                                                <tr
                                                                                    :class="index1 % 2 === 0 ? 'bg-lime-50' : 'bg-lime-100'">
                                                                                    <td
                                                                                        class="px-6 py-4 whitespace-nowrap font-medium text-gray-900">
                                                                                        {{ subscriptionUpgrade.id }}
                                                                                    </td>
                                                                                    <td
                                                                                        class="px-6 py-4 whitespace-nowrap font-medium text-gray-900">
                                                                                        {{ subscriptionUpgrade.upgradeId }}
                                                                                    </td>
                                                                                    <td
                                                                                        class="px-6 py-4 whitespace-nowrap font-medium text-gray-900">
                                                                                        <div @click="openApproveOrDenyModal(subscriptionUpgrade, organization.id, subscription.id, subscription.isActive)"
                                                                                            :class="[subscription.isActive && subscriptionUpgrade.status == SubscriptionUpgradeStatusEnum.Created ? 'cursor-pointer text-sky-500 font-medium underline' : '']">
                                                                                            {{
                                                                                                subscriptionUpgrade.statusString
                                                                                            }}
                                                                                        </div>
                                                                                    </td>

                                                                                    <td
                                                                                        class="px-6 py-4 whitespace-nowrap font-medium text-gray-900">
                                                                                        {{
                                                                                            subscriptionUpgrade.upgradeTypeString
                                                                                        }}
                                                                                    </td>
                                                                                    <td
                                                                                        class="px-6 py-4 whitespace-nowrap font-medium text-gray-900">
                                                                                        {{ subscriptionUpgrade.newValue }}
                                                                                    </td>
                                                                                    <td
                                                                                        class="px-6 py-4 whitespace-nowrap font-medium text-gray-900">
                                                                                        {{
                                                                                            subscriptionUpgrade.creatorUserEMail
                                                                                        }}
                                                                                    </td>
                                                                                    <td
                                                                                        class="px-6 py-4 whitespace-nowrap font-medium text-gray-900">
                                                                                        {{ subscriptionUpgrade.creatorNote
                                                                                        }}
                                                                                    </td>
                                                                                    <td
                                                                                        class="px-6 py-4 whitespace-nowrap font-medium text-gray-900">
                                                                                        {{
                                                                                            formatDateLong(subscriptionUpgrade.approvedDate)
                                                                                        }}
                                                                                    </td>
                                                                                    <td
                                                                                        class="px-6 py-4 whitespace-nowrap font-medium text-gray-900">
                                                                                        {{
                                                                                            subscriptionUpgrade.approverUserEMail
                                                                                        }}
                                                                                    </td>
                                                                                    <td
                                                                                        class="px-6 py-4 whitespace-nowrap font-medium text-gray-900">
                                                                                        {{ subscriptionUpgrade.approverNote
                                                                                        }}
                                                                                    </td>

                                                                                    <td
                                                                                        class="px-6 py-4 whitespace-nowrap font-medium text-gray-900">
                                                                                        {{
                                                                                            formatDateLong(subscriptionUpgrade.createdDate)
                                                                                        }}
                                                                                    </td>
                                                                                </tr>
                                                                            </template>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <!-- end -->
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </tbody>
                                            </table>
                                        </div>

                                        <!-- Subscriptions end -->
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="grid grid-cols-3 mx-auto space-x-4 my-10">
            <div class="bg-white shadow overflow-hidden sm:rounded-lg max-w-5xl border">
                <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                    <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                        <div class="ml-4 mt-2">
                            <h3 class="text-lg leading-6 font-medium text-gray-900">Editörle e-Posta Gönder</h3>
                        </div>
                        <div class="ml-4 mt-2 flex-shrink-0">
                            <button :disabled="isWorking" @click="sendEMail(mailSubjectQuill, mailBodyQuill, 'quill')"
                                type="button"
                                class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500">
                                Gönder
                            </button>
                        </div>
                    </div>
                </div>
                <div class="p-8">
                    <div class="grid gap-6">
                        <div>
                            <label for="target-subject" class="block text-sm font-medium text-gray-700">e-Posta
                                Konusu</label>
                            <input v-model="mailSubjectQuill" type="text" name="target-subject" id="target-subject"
                                autocomplete="email"
                                class="mt-1 focus:ring-sky-500 focus:border-sky-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                        </div>
                        <div>
                            <label for="target-mail-addresses" class="block text-sm font-medium text-gray-700">e-Posta
                                Adresleri</label>
                            <input v-model="mailtTargetsQuill" type="text" name="target-mail-addresses" id="target-subject"
                                autocomplete="email"
                                class="mt-1 focus:ring-sky-500 focus:border-sky-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                            <p class="mt-2 text-xs text-gray-500 whitespace-normal">Bu alan girilirse, seçilen organizasyon
                                yerine burada yazılan e-posta adreslerine gönderim yapılır.</p>
                        </div>

                        <div class="pb-20">
                            <label class="block text-sm font-medium text-gray-700">e-Posta İçeriği</label>
                            <QuillEditor ref="quillEditor" v-model:content="mailBodyQuill" theme="snow" contentType="html"
                                toolbar="essential" />
                        </div>
                        <div v-if="resultQuill">
                            <label for="about" class="block text-sm font-medium text-gray-700"> e-Posta gönderilen adresler
                            </label>
                            <div class="mt-1">
                                <div
                                    class="shadow-sm focus:ring-sky-500 focus:border-sky-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md">
                                    <div>
                                        <div class="p-4 justify-self-start self-start">
                                            <div v-for="(setting, index) in resultQuill" :key="index">{{ setting }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-white shadow overflow-hidden sm:rounded-lg max-w-5xl border">
                <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                    <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                        <div class="ml-4 mt-2">
                            <h3 class="text-lg leading-6 font-medium text-gray-900">HTML Olarak e-Posta Gönder</h3>
                        </div>
                        <div class="ml-4 mt-2 flex-shrink-0">
                            <button :disabled="isWorking" @click="sendEMail(mailSubjectHtml, mailBodyHtml, 'html')"
                                type="button"
                                class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500">
                                Gönder
                            </button>
                        </div>
                    </div>
                </div>
                <div class="p-8">
                    <div class="grid gap-6">
                        <div>
                            <label for="target-subject" class="block text-sm font-medium text-gray-700">e-Posta
                                Konusu</label>
                            <input v-model="mailSubjectHtml" type="text" name="target-subject" id="target-subject"
                                autocomplete="email"
                                class="mt-1 focus:ring-sky-500 focus:border-sky-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                        </div>
                        <div>
                            <label for="target-mail-addresses-html" class="block text-sm font-medium text-gray-700">e-Posta
                                Adresleri</label>
                            <input v-model="mailTargetsHtml" type="text" name="target-mail-addresses-html"
                                id="target-subject" autocomplete="email"
                                class="mt-1 focus:ring-sky-500 focus:border-sky-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                            <p class="mt-2 text-xs text-gray-500 whitespace-normal">Bu alan girilirse, seçilen organizasyon
                                yerine burada yazılan e-posta adreslerine gönderim yapılır.</p>
                        </div>

                        <div>
                            <label for="about" class="block text-sm font-medium text-gray-700"> e-Posta İçeriği </label>
                            <div class="mt-1">
                                <textarea v-model="mailBodyHtml" id="about" name="about" rows="10"
                                    class="shadow-sm focus:ring-sky-500 focus:border-sky-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md" />
                            </div>
                            <p class="mt-2 text-sm text-gray-500">Buraya e-posta içeriği HTML olarak girilir.</p>
                        </div>
                        <div v-if="resultHtml">
                            <label for="about" class="block text-sm font-medium text-gray-700"> e-Posta gönderilen adresler
                            </label>
                            <div class="mt-1">
                                <div
                                    class="shadow-sm focus:ring-sky-500 focus:border-sky-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md">
                                    <div>
                                        <div class="p-4 justify-self-start self-start">
                                            <div v-for="(setting, index) in resultHtml" :key="index">{{ setting }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-white shadow overflow-hidden sm:rounded-lg max-w-5xl border">
                <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                    <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                        <div class="ml-4 mt-2">
                            <h3 class="text-lg leading-6 font-medium text-gray-900">Sistem Ayarları</h3>
                        </div>
                        <div class="ml-4 mt-2 flex-shrink-0">
                            <button :disabled="isWorking" @click="getAllSettings" type="button"
                                class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-sky-600 hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500">
                                Sistem Ayarlarını Gör
                            </button>
                        </div>
                    </div>
                </div>
                <div class="p-8" v-if="allSettings">
                    <div class="grid gap-6">
                        <div>
                            <label for="about" class="block text-sm font-medium text-gray-700"> Sistem ayarları </label>
                            <div class="mt-1">
                                <div
                                    class="shadow-sm focus:ring-sky-500 focus:border-sky-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md">
                                    <div>
                                        <div class="p-4 justify-self-start self-start">
                                            <div v-for="(setting, index) in allSettings" :key="index">{{ setting }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ActionTypes } from "@/store/action-types";
import {
    GetOrganizationsRequest,
    GetOrganizationsResult,
    GetOrganizationsResultItem,
    CreateSubscriptionUpgradeRequest,
    CreateSubscriptionUpgradeResult,
    SubscriptionUpgradeUpgradeTypeEnum,
    SubscriptionUpgradeStatusEnum,
    ApproveOrDenySubscriptionUpgradeRequest,
    ApproveOrDenySubscriptionUpgradeResult,
    GetOrganizationsResultUpgrade,
    GetPlansResultPlan,
    GetAllSettingsRequest,
    GetAllSettingsResult,
    GetSubscriptionUpgradeUpgradeTypeEnumsRequest,
    GetSubscriptionUpgradeUpgradeTypeEnumsResult,
    EnumItem,
    SendEMailToOrganizationsRequest,
    SendEMailToOrganizationsResult,
} from "@/types/SharedTypes";
import { StaticPhrases } from "@/types/StaticPhrases";
import StringHelper from "@/types/StringHelper";
import axios from "axios";
import { nanoid } from "nanoid";
import { defineComponent } from "vue";
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

export interface GetOrganizationsResultItemExtended extends GetOrganizationsResultItem {
    isExpanded: boolean;
    isSelected: boolean;
}

export default defineComponent({
    name: "OrganizationsList",
    components: {
        Dialog,
        DialogOverlay,
        TransitionChild,
        TransitionRoot,
        QuillEditor,
    },
    data() {
        return {
            organizations: null as Array<GetOrganizationsResultItemExtended> | null,
            plans: null as GetPlansResultPlan[] | null,
            createSubscriptionUpgradeRequest: null as null | CreateSubscriptionUpgradeRequest,
            approveOrDenySubscriptionUpgradeRequest: null as null | ApproveOrDenySubscriptionUpgradeRequest,
            SubscriptionUpgradeUpgradeTypeEnum,
            SubscriptionUpgradeStatusEnum,
            allSettings: null as string[] | null,
            resultQuill: null as string[] | null,
            resultHtml: null as string[] | null,
            isWorking: false,
            subscriptionUpgradeUpgradeTypeEnums: null as EnumItem[] | null,
            organizationFilter: "",
            eMailFilter: "",
            planFilter: "",
            isAllSelected: false,
            mailBodyQuill: "",
            mailBodyHtml: "",
            mailSubjectQuill: "",
            mailtTargetsQuill: "",
            mailSubjectHtml: "",
            mailTargetsHtml: "",
        };
    },
    mounted() {
        this.getSubscriptionUpgradeUpgradeTypeEnums();
    },
    methods: {
        selectAll() {
            if (this.organizations) {
                if (this.isAllSelected) {
                    this.organizations.forEach((organization) => {
                        organization.isSelected = false;
                    });
                } else {
                    this.organizations.forEach((organization) => {
                        organization.isSelected = true;
                    });
                }
                this.isAllSelected = !this.isAllSelected;
            }
        },
        sendEMail(mailSubject: string, body: string, quillOrHtml: string) {
            if (quillOrHtml === "quill") {
                if (!this.organizations && !this.mailtTargetsQuill) {
                    return;
                }
                if (!this.mailtTargetsQuill && this.organizations && this.organizations.filter((organization) => organization.isSelected).map((organization) => organization.id).length === 0) {
                    return;
                }
            }
            if (quillOrHtml === "html") {
                if (!this.organizations && !this.mailTargetsHtml) {
                    return;
                }
                if (!this.mailTargetsHtml && this.organizations && this.organizations.filter((organization) => organization.isSelected).map((organization) => organization.id).length === 0) {
                    return;
                }
            }

            // if (this.organizations.filter((organization) => organization.isSelected).map((organization) => organization.id).length === 0) {
            //     return;
            // }
            const yourConfig = {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.getIdToken,
                },
            };
            const req = {
                loginSessionId: this.$store.getters.getLoginSessionId,
                requestId: nanoid(),
                organizationIds: this.organizations?.filter((organization) => organization.isSelected).map((organization) => organization.id),
                mailAddresses: quillOrHtml === "quill" ? this.mailtTargetsQuill : this.mailTargetsHtml,
                mailSubject: mailSubject,
                mailBody: body,
            } as SendEMailToOrganizationsRequest;
            this.isWorking = true;
            axios
                .post(this.$store.getters.getApiUrl + "admin/sendEMailToOrganizations", req, yourConfig)
                .then((e) => {
                    if (e.data.error !== null) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, e.data.error);
                    } else {
                        const result = e.data.result as SendEMailToOrganizationsResult;
                        if (quillOrHtml === "quill") {
                            this.resultQuill = result.eMails;
                        }
                        if (quillOrHtml === "html") {
                            this.resultHtml = result.eMails;
                        }
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error", error);
                        if (error.response.status === 401) {
                            this.$store.dispatch(ActionTypes.TOASTERROR, "Yetkisiz erişim.");
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTERROR, error.response);
                        }
                    } else if (error.request) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, StaticPhrases.serverNotFoundError);
                    } else {
                        this.$store.dispatch(ActionTypes.TOASTERROR, error.message);
                    }
                })
                .finally(() => {
                    this.isWorking = false;
                });
        },
        getOrganizations() {
            this.organizations = null;
            const yourConfig = {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.getIdToken,
                },
            };
            const req = {
                loginSessionId: this.$store.getters.getLoginSessionId,
                requestId: nanoid(),
                organizationFilter: this.organizationFilter,
                eMailFilter: this.eMailFilter,
                planFilter: this.planFilter,
            } as GetOrganizationsRequest;
            axios
                .post(this.$store.getters.getApiUrl + "admin/getOrganizations", req, yourConfig)
                .then((e) => {
                    if (e.data.error !== null) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, e.data.error);
                    } else {
                        const result = e.data.result as GetOrganizationsResult;
                        this.organizations = result.items.map((item) => {
                            return {
                                ...item,
                                isExpanded: false,
                                isSelected: false,
                            };
                        });
                        this.plans = result.plans;
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error", error);
                        if (error.response.status === 401) {
                            this.$store.dispatch(ActionTypes.TOASTERROR, "Yetkisiz erişim.");
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTERROR, error.response);
                        }
                    } else if (error.request) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, StaticPhrases.serverNotFoundError);
                    } else {
                        this.$store.dispatch(ActionTypes.TOASTERROR, error.message);
                    }
                })
                .finally(() => {
                    //
                });
        },
        getAllSettings() {
            const yourConfig = {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.getIdToken,
                },
            };
            const req = {
                loginSessionId: this.$store.getters.getLoginSessionId,
                requestId: nanoid(),
            } as GetAllSettingsRequest;
            axios
                .post(this.$store.getters.getApiUrl + "admin/getAllSettings", req, yourConfig)
                .then((e) => {
                    if (e.data.error !== null) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, e.data.error);
                    } else {
                        const result = e.data.result as GetAllSettingsResult;
                        this.allSettings = result.allSettings;
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error", error);
                        if (error.response.status === 401) {
                            this.$store.dispatch(ActionTypes.TOASTERROR, "Yetkisiz erişim.");
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTERROR, error.response);
                        }
                    } else if (error.request) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, StaticPhrases.serverNotFoundError);
                    } else {
                        this.$store.dispatch(ActionTypes.TOASTERROR, error.message);
                    }
                })
                .finally(() => {
                    //
                });
        },
        openApproveOrDenyModal(subscriptionUpgrade: GetOrganizationsResultUpgrade, organizationId: number, subscriptionId: number, subscriptionIsActive: boolean) {
            if (subscriptionIsActive === false) return;
            if (subscriptionUpgrade.status !== SubscriptionUpgradeStatusEnum.Created) {
                // this.$store.dispatch(ActionTypes.TOASTERROR, "Sadece onay bekleyen işlemlere işlem yapılabilir.");
                return;
            }
            this.approveOrDenySubscriptionUpgradeRequest = {
                loginSessionId: this.$store.getters.getLoginSessionId,
                requestId: nanoid(),
                planUpgradeRequestId: subscriptionUpgrade.upgradeId,
                subscriptionUpgradeId: subscriptionUpgrade.id,
                approverNote: "",
                passwordForOperation: "",
                approveOrDeny: false,
                organizationId: organizationId,
                subscriptionId: subscriptionId,
            } as ApproveOrDenySubscriptionUpgradeRequest;
        },
        openModalForCreateRequest(organizationId: number, subscriptionId: number, upgradeType: SubscriptionUpgradeUpgradeTypeEnum, subscriptionIsActive: boolean) {
            if (subscriptionIsActive === false) return;
            this.createSubscriptionUpgradeRequest = {
                loginSessionId: this.$store.getters.getLoginSessionId,
                requestId: nanoid(),
                organizationId: organizationId,
                subscriptionId: subscriptionId,
                upgradeType: upgradeType,
                newValue: "",
            } as CreateSubscriptionUpgradeRequest;
        },
        ApproveOrDenySubscriptionUpgrade(approveOrDeny: boolean) {
            if (this.approveOrDenySubscriptionUpgradeRequest === null) {
                this.$store.dispatch(ActionTypes.TOASTWARNING, "Hata.");
                return;
            }
            if (!this.approveOrDenySubscriptionUpgradeRequest.approverNote) {
                this.$store.dispatch(ActionTypes.TOASTWARNING, "Açıklama giriniz.");
                return;
            }
            if (!this.approveOrDenySubscriptionUpgradeRequest.passwordForOperation) {
                this.$store.dispatch(ActionTypes.TOASTWARNING, "Şifre giriniz.");
                return;
            }
            this.approveOrDenySubscriptionUpgradeRequest.approveOrDeny = approveOrDeny;
            const yourConfig = {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.getIdToken,
                },
            };
            this.isWorking = true;
            axios
                .post(this.$store.getters.getApiUrl + "admin/approveOrDenySubscriptionUpgrade", this.approveOrDenySubscriptionUpgradeRequest, yourConfig)
                .then((e) => {
                    if (e.data.error !== null) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, e.data.error);
                    } else {
                        const result = e.data.result as ApproveOrDenySubscriptionUpgradeResult;
                        this.$store.dispatch(ActionTypes.TOASTSUCCESS, "İşlemle ile ilgili e-posta gönderildi: " + (result.eMailSent ? "Evet" : "Hayır") + ". Talep onay/red işlemi tamamlandı: " + (result.savedToDb ? "Evet" : "Hayır"));
                        this.approveOrDenySubscriptionUpgradeRequest = null;
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error", error);
                        if (error.response.status === 401) {
                            this.$store.dispatch(ActionTypes.TOASTERROR, "Yetkisiz erişim.");
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTERROR, error.response);
                        }
                    } else if (error.request) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, StaticPhrases.serverNotFoundError);
                    } else {
                        this.$store.dispatch(ActionTypes.TOASTERROR, error.message);
                    }
                })
                .finally(() => {
                    this.isWorking = false;
                });
        },
        planUpgrade() {
            if (this.createSubscriptionUpgradeRequest === null) {
                this.$store.dispatch(ActionTypes.TOASTWARNING, "Hata.");
                return;
            }
            if (!this.createSubscriptionUpgradeRequest.creatorNote) {
                this.$store.dispatch(ActionTypes.TOASTWARNING, "Açıklama giriniz.");
                return;
            }
            if (!this.createSubscriptionUpgradeRequest.newValue) {
                this.$store.dispatch(ActionTypes.TOASTWARNING, "Yeni değeri giriniz.");
                return;
            }
            const yourConfig = {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.getIdToken,
                },
            };

            this.isWorking = true;
            axios
                .post(this.$store.getters.getApiUrl + "admin/createSubscriptionUpgrade", this.createSubscriptionUpgradeRequest, yourConfig)
                .then((e) => {
                    if (e.data.error !== null) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, e.data.error);
                    } else {
                        const result = e.data.result as CreateSubscriptionUpgradeResult;
                        this.$store.dispatch(ActionTypes.TOASTSUCCESS, "Talep ile ilgili e-posta gönderildi: " + (result.eMailSent ? "Evet" : "Hayır") + ". Talep kayıt edildi: " + (result.savedToDb ? "Evet" : "Hayır"));
                        this.createSubscriptionUpgradeRequest = null;
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("error", error);
                        if (error.response.status === 401) {
                            this.$store.dispatch(ActionTypes.TOASTERROR, "Yetkisiz erişim.");
                        } else {
                            this.$store.dispatch(ActionTypes.TOASTERROR, error.response);
                        }
                    } else if (error.request) {
                        this.$store.dispatch(ActionTypes.TOASTERROR, StaticPhrases.serverNotFoundError);
                    } else {
                        this.$store.dispatch(ActionTypes.TOASTERROR, error.message);
                    }
                })
                .finally(() => {
                    this.isWorking = false;
                });
        },
        formatDateLong(inputDate: string | null): string {
            if (inputDate) return StringHelper.formatDateLong(inputDate);
            return "";
        },
        getPlanName(name: string): string {
            if (this.plans && this.plans.length > 0) {
                const plan = this.plans.find((p) => p.name === name);
                if (plan) {
                    return plan.displayNameForWeb;
                }
            }
            return "";
        },
        getSubscriptionUpgradeUpgradeType(enumValue: SubscriptionUpgradeUpgradeTypeEnum): string {
            if (this.subscriptionUpgradeUpgradeTypeEnums && this.subscriptionUpgradeUpgradeTypeEnums.length > 0) {
                const plan = this.subscriptionUpgradeUpgradeTypeEnums.find((p) => p.key === enumValue);
                if (plan) {
                    return plan.description;
                }
            }
            return "";
        },
        getSubscriptionUpgradeUpgradeTypeEnums() {
            const yourConfig = {
                headers: {
                    Authorization: "Bearer " + this.$store.getters.getIdToken,
                },
            };
            const req = {
                loginSessionId: this.$store.getters.getLoginSessionId,
                requestId: nanoid(),
                displayLanguage: this.$i18n.locale,
            } as GetSubscriptionUpgradeUpgradeTypeEnumsRequest;
            this.isWorking = true;
            axios
                .post(this.$store.getters.getApiUrl + "enums/getSubscriptionUpgradeUpgradeTypeEnums", req, yourConfig)
                .then((e) => {
                    if (e.data.error !== null) {
                        //
                    } else {
                        const getSubscriptionUpgradeUpgradeTypeEnumsResult = e.data.result as GetSubscriptionUpgradeUpgradeTypeEnumsResult;
                        if (getSubscriptionUpgradeUpgradeTypeEnumsResult.enums) {
                            this.subscriptionUpgradeUpgradeTypeEnums = getSubscriptionUpgradeUpgradeTypeEnumsResult.enums;
                        }
                    }
                })
                // .catch((error: Error | AxiosError) => {
                //     //
                // })
                .finally(() => {
                    this.isWorking = false;
                });
        },
    },
});
</script>
