import { ActionTree, ActionContext } from "vuex";
import { State } from "./state";
import { Mutations } from "./mutations";
import { ActionTypes } from "./action-types";
import { useToast } from "vue-toastification";

const toast = useToast();

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(key: K, payload: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, State>, "commit">;

export interface Actions {
  [ActionTypes.TOASTERROR]({ commit }: AugmentedActionContext, message: string): boolean;
  [ActionTypes.TOASTSUCCESS]({ commit }: AugmentedActionContext, message: string): boolean;
  [ActionTypes.TOASTWARNING]({ commit }: AugmentedActionContext, message: string): boolean;
  [ActionTypes.TOASTCLEAR]({ commit }: AugmentedActionContext): boolean;
}

export const actions: ActionTree<State, State> & Actions = {
  [ActionTypes.TOASTERROR]({ commit }, message: string) {
    toast.error(message);
    return true;
  },
  [ActionTypes.TOASTSUCCESS]({ commit }, message: string) {
    toast.success(message);
    return true;
  },
  [ActionTypes.TOASTWARNING]({ commit }, message: string) {
    toast.warning(message);
    return true;
  },
  [ActionTypes.TOASTCLEAR]({ commit }) {
    toast.clear();
    return true;
  },
};
